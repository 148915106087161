import * as React from 'react';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import RankingFilter from 'pages/Report/components/AccountSelect/components/RankingFilter';
import { AccountData, AccountQuery, AccountQueryOp } from 'redux/ducks/reportFilter/selectors';
import {
  Divider,
  InputAdornment,
  TextField,
  makeStyles,
  InputLabel,
  List,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  SvgIconProps,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Dialog,
  DialogContentText,
  Grid,
  Radio
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import PersonIcon from '@material-ui/icons/Person';
import DeleteIcon from '@material-ui/icons/Delete';
import { SavedFilterSelection } from 'redux/ducks/settings';
import SaveSelectionDialog from 'pages/Report/components/AccountSelect/components/SaveSelectionDialog';
import Select from 'pages/Report/components/Select';
import SelectItem from 'pages/Report/components/Select/components/SelectItem';
import classNames from 'classnames';
import Avatar, { AvatarProps } from '@material-ui/core/Avatar/Avatar';
import { Spinner } from 'components/LoadingPlaceholder';

export interface AccountSelectProps {
  availableAccounts: AccountData[];
  accountQuery?: AccountQuery;
  selectedAccounts: string[];
  onChange: (accountIds: string[]) => void;
  savedSelections: SavedFilterSelection[];
  onSavedSelectionChange: (selections: SavedFilterSelection[]) => void;
  avatarProps?: AvatarProps;
  iconProps?: SvgIconProps;
  disableRankFilters?: boolean;
  disabled?: boolean;
}

interface SelectionDraft {
  selection: string[] | AccountQuery;
  changed?: boolean;
}

const AccountSelect: React.FunctionComponent<AccountSelectProps> = (props) => {
  const intl = useIntl();
  const {
    selectedAccounts,
    availableAccounts,
    accountQuery,
    onChange,
    savedSelections,
    onSavedSelectionChange,
    avatarProps = {},
    iconProps = {},
    disableRankFilters,
    disabled
  } = props;
  const { className: avatarClassName, ...avatarRestProps } = avatarProps;
  const { className: iconClassName, ...iconRestProps } = iconProps;

  const [searchTerm, setSearchTerm] = useState<string>('');
  const [open, setOpen] = React.useState<boolean>(false);
  const [selection, setSelection] = React.useState<string>('');
  const [pending, setPending] = React.useState(false);
  const [draft, setDraft] = React.useState<SelectionDraft>({
    selection: accountQuery || selectedAccounts,
    changed: false
  });
  const classes = useStyles(props);

  const activeSelection = Array.isArray(draft.selection) ? draft.selection : [];
  const activeQuery = !Array.isArray(draft.selection) ? draft.selection : undefined;

  React.useEffect(() => {
    setDraft({
      selection: accountQuery || selectedAccounts,
      changed: false
    });
    setPending(false);
  }, [accountQuery, selectedAccounts]);

  const renderValue = React.useCallback(() => {
    if (accountQuery) {
      const { op, value } = accountQuery;
      return intl.formatMessage({ id: `report.filter.${op}_selection` }, { value });
    }

    if (selectedAccounts.length === 0) {
      return intl.formatMessage({ id: 'report.filter.no_selection' });
    }

    const accountMap = availableAccounts.reduce(
      (all, cur) => ({ ...all, [cur.id]: cur }),
      {} as {
        [id: string]: AccountData;
      }
    );

    return selectedAccounts
      .filter((id) => accountMap[id])
      .map((account) => accountMap[account]?.name?.trim())
      .join(', ');
  }, [selectedAccounts, availableAccounts, accountQuery]);

  const handleChange = (selection: SelectionDraft['selection']) => {
    if (!Array.isArray(selection)) {
      if (accountQuery) {
        const changed = selection.value !== accountQuery.value || selection.op !== accountQuery.op;
        setDraft({ selection, changed });
        return;
      }
      setDraft({ selection, changed: true });
      return;
    }

    if (accountQuery) {
      setDraft({ selection, changed: true });
      return;
    }

    setDraft({
      selection,
      changed:
        selection.length !== selectedAccounts.length ||
        selectedAccounts.some((account) => !selection.includes(account))
    });
  };

  const handleSelectAllAccounts = () => {
    handleChange(availableAccounts.map((account) => account.id));
  };

  const handleSelectCurrentAccount = () => {
    const currentAccount = availableAccounts.find((account) => account.isCurrentAccount);
    if (!currentAccount) {
      return;
    }
    handleChange([currentAccount.id]);
  };

  const handleSelectRanking = (ranking: string, query: AccountQuery) => {
    handleChange(query);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    event.stopPropagation();
    const {
      target: { value }
    } = event;
    setSearchTerm(value);
  };

  const handleSaveSelection = (selectionName: string) => {
    onSavedSelectionChange([
      ...savedSelections,
      {
        name: selectionName,
        accountIds: activeSelection
      }
    ]);
  };

  const handleRemoveSelection = (selectionName: string) => {
    onSavedSelectionChange(savedSelections.filter((selection) => selection.name !== selectionName));
  };

  const handleSelectChange =
    (accountId: string) => (event: React.ChangeEvent<HTMLSelectElement>) => {
      event.preventDefault();
      const currentSelectedIds = activeSelection;
      const isSelected = !currentSelectedIds.includes(accountId);
      const nextSelectedIds = isSelected
        ? [...currentSelectedIds, accountId]
        : currentSelectedIds.filter((id) => id !== accountId);
      if (nextSelectedIds.length === 0) {
        return; // must have 1 selected
      } else {
        handleChange(nextSelectedIds);
      }
    };

  const handleCloseDialog = () => {
    setOpen(false);
    setSelection('');
  };

  const handleOpenDialog = (name: string): void => {
    setSelection(name);
    setOpen(true);
  };

  const handleDelete = (): void => {
    handleCloseDialog();
    handleRemoveSelection(selection);
  };

  const handleApplyChanges = () => {
    const { changed, selection } = draft;
    if (!changed || pending) {
      return;
    }
    setPending(true);
    onChange(Array.isArray(selection) ? selection : [`${selection.op}${selection.value}`]);
  };

  const handleResetChanges = () => {
    setDraft({
      selection: accountQuery || selectedAccounts,
      changed: false
    });
  };

  // Probably better idea to use autocomplete, which is meant for complex select cases
  // We only utilize dropdown menu / select renderer of the select component

  const hasSelectedAll = activeSelection.length === availableAccounts.length;
  const hasSelectedCurrent =
    activeSelection.length === 1 &&
    Boolean(
      availableAccounts.find(
        (account) => account.id === activeSelection[0] && account.isCurrentAccount
      )
    );

  return (
    <Select
      fullWidth
      disabled={disabled}
      renderValue={renderValue}
      buttonProps={{
        startIcon: (
          <Avatar
            className={classNames(classes.avatar, { [avatarClassName]: Boolean(avatarClassName) })}
            {...avatarRestProps}
          >
            <PersonIcon
              className={classNames(classes.icon, { [iconClassName]: Boolean(iconClassName) })}
              {...iconRestProps}
            />
          </Avatar>
        )
      }}
      menuProps={{ MenuListProps: { disablePadding: true } }}
    >
      <SelectItem
        onClick={handleSelectAllAccounts}
        disabled={hasSelectedAll}
        selected={hasSelectedAll}
        checkbox
      >
        <ListItemText>
          {intl.formatMessage({ id: 'report.filter.selectAllDepartments' })}
        </ListItemText>
      </SelectItem>
      <SelectItem
        onClick={handleSelectCurrentAccount}
        disabled={hasSelectedCurrent}
        selected={hasSelectedCurrent}
        checkbox
      >
        <ListItemText>
          {intl.formatMessage({ id: 'report.filter.selectCurrentDepartment' })}
        </ListItemText>
      </SelectItem>
      <Divider />
      {!disableRankFilters && (
        <SelectItem disableRipple>
          <RankingFilter
            type={AccountQueryOp.top}
            accountQuery={activeQuery}
            onChange={handleSelectRanking}
          />
        </SelectItem>
      )}
      {!disableRankFilters && (
        <SelectItem disableRipple>
          <RankingFilter
            type={AccountQueryOp.bottom}
            accountQuery={activeQuery}
            onChange={handleSelectRanking}
          />
        </SelectItem>
      )}
      <Divider />
      {savedSelections.map((selection) => (
        <SelectItem key={selection.name} onClick={() => handleChange(selection.accountIds)}>
          <ListItemIcon className={classes.radioListItemIcon}>
            <Radio
              disableRipple
              size='small'
              color='primary'
              checked={
                activeSelection.length === selection.accountIds.length &&
                activeSelection.every((id) => selection.accountIds.includes(id))
              }
            />
          </ListItemIcon>
          <ListItemText>{selection.name}</ListItemText>
          <ListItemIcon className={classes.deleteListItemIcon}>
            <DeleteIcon
              fontSize='small'
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                handleOpenDialog(selection.name);
              }}
            />
          </ListItemIcon>
        </SelectItem>
      ))}

      <Dialog open={open} onClose={handleCloseDialog} aria-labelledby='form-dialog-title'>
        <DialogTitle id='form-dialog-title'>
          {intl.formatMessage({ id: 'base.confirm.deletion.title' })}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-selections'>
            {intl.formatMessage({ id: 'base.confirm.deletion.description' }, { name: selection })}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant='text' onClick={handleCloseDialog} color='primary'>
            {intl.formatMessage({ id: 'base.cancel' })}
          </Button>
          <Button
            variant='contained'
            onClick={handleDelete}
            color='primary'
            className={classes.deleteButton}
          >
            {intl.formatMessage({ id: 'data_table.delete' })}
          </Button>
        </DialogActions>
      </Dialog>

      <Divider />
      <List>
        <ListSubheader disableSticky>
          <InputLabel className={classes.searchHeader}>
            {intl.formatMessage({ id: 'report.filter.orSelectAccountsIndividually' })} (
            {activeSelection.length})
          </InputLabel>
        </ListSubheader>
        <SelectItem disableLookup disableRipple disableHover tabIndex={'-1'}>
          <TextField
            type='search'
            className={classes.searchTextField}
            fullWidth
            placeholder={intl.formatMessage({ id: 'search' })}
            value={searchTerm}
            variant='outlined'
            size='small'
            onChange={handleSearchChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <SearchIcon className={classes.searchIcon} />
                </InputAdornment>
              )
            }}
          />
        </SelectItem>
        <List className={classes.scrollableList}>
          {availableAccounts
            .filter((account) => account.name?.toLowerCase().includes(searchTerm.toLowerCase()))
            .map((account) => (
              <SelectItem
                key={account.id}
                onClick={handleSelectChange(account.id)}
                selected={activeSelection.some((selected) => selected === account.id)}
                checkbox
              >
                <ListItemText>{account.name}</ListItemText>
              </SelectItem>
            ))}
        </List>
        <SelectItem disableLookup disableRipple disableHover>
          <SaveSelectionDialog
            onSave={handleSaveSelection}
            disabled={activeSelection.length === 0}
          />
        </SelectItem>
      </List>
      {draft.changed && (
        <SelectItem
          disableLookup
          disableRipple
          disableHover
          style={{
            position: 'sticky',
            bottom: 0,
            backgroundColor: '#fff',
            borderTop: '1px solid rgba(0, 0, 0, 0.12)'
          }}
        >
          <Grid container item justify='space-between'>
            <Button variant='text' color='primary' onClick={handleResetChanges}>
              {intl.formatMessage({ id: 'base.undo' })}
            </Button>
            <Button
              variant='outlined'
              color='primary'
              onClick={handleApplyChanges}
              disabled={pending}
            >
              {pending ? <Spinner size='xs' /> : intl.formatMessage({ id: 'base.apply' })}
            </Button>
          </Grid>
        </SelectItem>
      )}
    </Select>
  );
};

const useStyles = makeStyles((theme) => ({
  avatar: {
    height: 'initial',
    width: 'initial'
  },
  icon: {
    color: 'inherit',
    fontSize: 'inherit'
  },
  radioListItemIcon: {
    justifyContent: 'flex-start',
    marginLeft: '-9px'
  },
  deleteListItemIcon: {
    justifyContent: 'flex-end'
  },
  scrollableList: {
    maxHeight: 300,
    overflowY: 'auto'
  },
  searchHeader: {
    fontWeight: 'bold'
  },
  searchTextField: {
    '& .MuiInputBase-input::placeholder': {
      color: theme.palette.primary.main
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: theme.palette.primary.light
      },
      '&:hover fieldset': {
        borderColor: theme.palette.primary.main
      },
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.primary.main
      }
    }
  },
  searchIcon: {
    color: theme.palette.primary.light
  },
  deleteButton: {
    backgroundColor: '#ff4040'
  }
}));

export default AccountSelect;
